import React from "react"
import PrismicLink from "../PrismicLink"
import PrismicRichText from "../PrismicRichText"
import { Image } from "../VindiciaComponents"
import logo from "../../images/vindicia-new-logo-footer-white.png"
import logoWhite from "../../images/vindicia-logo-vertical-white.png"
import FooterContent from "./FooterContent"

const pickEveryNthElem = (data, { n, total }) => data.filter((_, i) => i % total === n)

const Footer = ({ copyright, data, whiteLogo = false }) => {
  const firstRow = pickEveryNthElem(data, { total: 3, n: 0 })
  const secondRow = pickEveryNthElem(data, { total: 3, n: 1 })
  const thirdRow = pickEveryNthElem(data, { total: 3, n: 2 })
  return (
    <footer style={{ backgroundColor: "#000" }} className="uk-section uk-padding-remove-bottom">
      <div className="uk-container">
        <div className="uk-grid-margin-large" data-uk-grid>
          <div className="uk-flex uk-flex-column uk-text-left uk-width-1-1@m uk-width-1-4@l">
            <a href="/" className="uk-margin-bottom">
              <Image
                style={{ maxWidth: "190px" }}
                src={whiteLogo ? logoWhite : logo}
                alt="Vindicia Logo"
              />
            </a>
            <div className="uk-margin-auto-top" style={{ maxWidth: "190px" }}>
              <p className="uk-text-small">
                Give us a follow to keep up with product offers and industry news:
              </p>
              <div className="sc-only social">
                <a
                  href="https://www.facebook.com/vindicia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="uk-icon-button uk-margin-right"
                  data-uk-icon="icon: facebook"
                ></a>
                <a
                  href="https://twitter.com/Vindicia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="uk-icon-button uk-margin-right"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <path d="m15.08,2.1h2.68l-5.89,6.71,6.88,9.1h-5.4l-4.23-5.53-4.84,5.53H1.59l6.24-7.18L1.24,2.1h5.54l3.82,5.05,4.48-5.05Zm-.94,14.23h1.48L6,3.61h-1.6l9.73,12.71h0Z"></path>
                  </svg>
                </a>
                <a
                  href="http://www.linkedin.com/company/vindicia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="uk-icon-button"
                  data-uk-icon="linkedin"
                ></a>
              </div>
            </div>
          </div>
          <div className="uk-width-1-3@s uk-width-1-4@l">
            {firstRow.map((slice, i) => {
              return <FooterContent key={i} slice={slice} />
            })}
          </div>
          <div className="uk-width-1-3@s uk-width-1-4@l">
            {secondRow.map((slice, i) => {
              return <FooterContent key={i} slice={slice} />
            })}
          </div>
          <div className="uk-width-1-3@s uk-width-1-4@l">
            {thirdRow.map((slice, i) => {
              return <FooterContent key={i} slice={slice} />
            })}
          </div>
        </div>
      </div>

      <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
        <span className="uk-text-small uk-text-muted">
          <PrismicRichText
            render={copyright}
            serializeHyperlink={(_type, element, _content) => {
              return <PrismicLink to={element.data.url} />
            }}
          />
        </span>
      </div>
    </footer>
  )
}

export default Footer
