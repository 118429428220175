import React from "react"
import { RichText } from "prismic-reactjs"
import PrismicLink from "./PrismicLink"

const PrismicRichText = ({ serializeHyperlink, ...props }) => (
  <RichText
    serializeHyperlink={(_type, element, _content, children) => {
      return (
        <PrismicLink key={element.data.url} to={element.data.url}>
          {children}
        </PrismicLink>
      )
    }}
    {...props}
  />
)

export default PrismicRichText
