import React from "react" //, {useState, useEffect} from "react";
import { Link } from "gatsby"

//import logo from "../../images/vindicia-logo.png";

const OffCanvas = props => {
  return (
    <div
      style={{ zIndex: "1002" }}
      id="offcanvas-nav"
      data-uk-offcanvas="mode: push; overlay: true; flip: true"
    >
      <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-dark">
        <button className="uk-offcanvas-close uk-close" type="button" data-uk-close></button>
        <ul
          className="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical uk-nav-parent-icon"
          data-uk-nav
        >
          <li className="uk-parent">
            <a href="#">Product</a>
            <ul className="uk-nav-sub">
            <li>
                <Link to={`/retain/overview/`}>Vindicia Retain - Failed Payment Recovery</Link>
              </li>
              <li>
                <Link to={`/products/subscribe/`}>Vindicia Subscribe - Subscription Management</Link>
              </li>
              <li>
                <Link to={`/products/integrations/`}>Integrations</Link>
              </li>
              <li>
                <Link to={`/products/security-and-compliance/`}>Security and Compliance</Link>
              </li>
            </ul>
          </li>

          <li className="uk-parent uk-nav-divider">
              <a href="#">Solutions</a>
              <ul className="uk-nav-sub">
                <li className="uk-nav-header">BY INDUSTRY</li>
                <li className="uk-margin-small-left">
                  <Link to={`/solutions/saas-and-software/`}>SaaS / Software</Link>
                </li>
                <li className="uk-margin-small-left">
                  <Link to={`/solutions/publishing-and-media/`}>Publishing / Media</Link>
                </li>
                <li className="uk-nav-header">BY ROLE</li>
                <li className="uk-margin-small-left">
                  <Link to={`/solutions/payments-professionals/`}>Payments Professionals</Link>
                </li>
              </ul>
            </li>

          <li className="uk-parent uk-nav-divider">
            <a href="#">Resources</a>
            <ul className="uk-nav-sub">
              <li>
                <Link to={`/resources/materials/`}>Resource Center</Link>
              </li>
              <li>
                <Link to={`/roi/`}>Revenue Calculator</Link>
              </li>
              <li>
                <Link to={`/webinars/`}>On-Demand Webinars</Link>
              </li>
              <li>
                <Link to={`/success-stories/`}>Success Stories</Link>
              </li>
              <li className="uk-parent">
                <span className="uk-nav-header">NEWS/EVENTS</span>
                <ul className="uk-nav-sub">
                  <li className="uk-margin-small-left">
                    <Link to={`/company/vindicia-in-the-news/`}>In The News</Link>
                  </li>
                  <li className="uk-margin-small-left">
                    <Link to={`/company/press-releases/`}>Press Releases</Link>
                  </li>
                </ul>
              </li>
              <li className="uk-parent">
                <span className="uk-nav-header">Technical Center</span>
                <ul className="uk-nav-sub">
                  <li className="uk-margin-small-left">
                    <a href="https://docs.vindicia.com/">Knowledge Center</a>
                  </li>
                  <li className="uk-margin-small-left">
                    <a href="https://docs.vindicia.com/category/developerhub">Developer Hub</a>
                  </li>
                  <li className="uk-margin-small-left">
                    <Link to={`/technical-center/customer-deployment-solutions/`}>
                      Client Services
                    </Link>
                  </li>
                  <li className="uk-margin-small-left">
                    <Link to={`/technical-center/vindicia-glossary/`}>Glossary</Link>
                  </li>
                  <li className="uk-margin-small-left">
                    <Link to={`/technical-center/faq/vindicia-subscribe-faq/`}>FAQ</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="uk-parent uk-nav-divider">
            <a href="#">Company</a>
            <ul className="uk-nav-sub">
              <li className="uk-nav-header">Who We Are</li>
              <li className="uk-margin-small-left">
                <Link to={`/company/about-vindicia`}>About Vindicia</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/team/`}>Our Team</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/our-people/`}>Our People</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/clients/`}>Our Clients</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/awards/`}>Awards</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/careers/`}>Careers</Link>
              </li>
              <li className="uk-nav-header">Partners</li>
              <li className="uk-margin-small-left">
                <Link to={`/company/our-partners/`}>Our Partners</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/become-partner/`}>Become a Partner</Link>
              </li>
              <li className="uk-margin-small-left">
                <Link to={`/company/refer-business/`}>Refer a Business</Link>
              </li>
            </ul>
          </li>

          <li className="uk-nav-divider">
            <Link to={`/blog/`}>Blog</Link>
          </li>

          <li className="uk-nav-divider">
            <Link to={`/contact-us/`}>Contact</Link>
          </li>
          <li className="uk-nav-divider">
            <a href="https://portal.vindicia.com/portal/login">Merchant Portal</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default OffCanvas
