import React from "react"
import classnames from "classnames"
import PrismicLink from "../PrismicLink"
import PrismicRichText from "../PrismicRichText"

const FooterContent = ({ slice }) => {
  if (slice.slice_type === "info_section") {
    return (
      <div>
        <hr
          style={{ border: "1px solid #4d13f2" }}
          className="uk-hidden@s uk-margin-small-bottom"
        />
        <h4 className="uk-margin-remove">{slice.primary.section_label}</h4>
        <div className="footer-content uk-margin-top">
          <PrismicRichText render={slice.primary.section_content.raw} />
        </div>
      </div>
    )
  }

  if (slice.slice_type === "navigation_item") {
    return (
      <div>
        <hr
          style={{ border: "1px solid #4d13f2" }}
          className="uk-hidden@s uk-margin-small-bottom"
        />
        <h4 className="uk-margin-remove">{slice.primary.section_label}</h4>
        <ul className="uk-list uk-margin-small-top">
          {slice.items.map((item, i) => (
            <li
              key={i}
              className={classnames("uk-margin-remove-top", {
                "uk-margin-small-left": item.level === "0",
                "uk-margin-left": item.level === "1",
              })}
            >
              {item.level === "label" ? (
                item.label
              ) : (
                <PrismicLink to={item.link}>{item.label}</PrismicLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}

export default FooterContent
