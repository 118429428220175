import React from "react"
import { Link } from "gatsby"

const PrismicLink = ({ children, to }) => {
  if (!to) return null
  const url = to.replace(/https:\/\/(www.)?vindicia\.com/, "")
  if (/https?:\/\//.test(url)) {
    return (
      <a href={url} title={children}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={url} title={children}>
        {children}
      </Link>
    )
  }
}

export default PrismicLink
