import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import OffCanvas from "./OffCanvas"
import { Image } from "../VindiciaComponents"
// import purpleLogo from "../../images/vindicia-new-logo-purple.png"
// import whiteLogo from "../../images/vindicia-new-logo-white.png"
import purpleLogo from "../../images/vindicia_amdocs.png"
import whiteLogo from "../../images/vindicia_amdocs_white.png"
//import logo from "../../images/vindicia-pride-logo.png"
import PropTypes from "prop-types"
import PrismicLink from "../PrismicLink"

const Navigation = ({ data, colorTheme = "light" }) => {
  const [isVisible, setIsVisible] = useState(true)

  const handleScroll =
    typeof window !== undefined
      ? () => (window.scrollY === 0 ? setIsVisible(true) : setIsVisible(false))
      : null

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <OffCanvas />
      <div id="top-navigation">
        <div
          className={`nav ${isVisible ? "Animate" : "ReAnimate"} ${
            colorTheme === "dark" ? "uk-light" : "uk-dark"
          }`}
          data-uk-sticky="cls-active: uk-box-shadow-medium; top: 100vh;"
        >
          <div className="uk-container">
            <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar>
              <div className="uk-navbar-left">
                <div className="uk-navbar-item uk-padding-remove-horizontal uk-margin-left@m">
                  <Link className="logo" to="/">
                    <Image
                      className="uk-logo logo"
                      src={colorTheme === "dark" ? whiteLogo : isVisible ? purpleLogo : whiteLogo}
                      alt="Vindicia logo"
                    />
                  </Link>
                </div>
              </div>
              <div className={`uk-navbar-right `}>
                <div className="uk-grid uk-grid-collapse uk-child-width-1-1" data-uk-grid>
                  {/*<p*/}
                  {/*  id="customer-portal-link"*/}
                  {/*  style={{ minHeight: "8px" }}*/}
                  {/*  className={`uk-navbar-item uk-flex-right uk-margin-small-top uk-margin-remove-bottom uk-padding-remove uk-visible@m ${*/}
                  {/*    isVisible ? "uk-visible" : "uk-hidden"*/}
                  {/*  }`}*/}
                  {/*>*/}
                  {/*  <a href="https://portal.vindicia.com/portal/login">Merchant Portal</a>*/}
                  {/*</p>*/}
                  <div>
                    <ul className="uk-navbar-nav uk-visible@m uk-flex-right navigation">
                      {data.map(({ primary, items, id }) => {
                        if (primary.section_link) {
                          return (
                            <li key={id}>
                              <PrismicLink to={primary.section_link}>
                                {primary.section_label}
                              </PrismicLink>
                            </li>
                          )
                        }
                        return (
                          <li key={id}>
                            <div className="uk-navbar-item">{primary.section_label}</div>
                            <div
                              className="uk-navbar-dropdown"
                              data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -15"
                            >
                              <ul className="uk-nav uk-navbar-dropdown-nav">
                                {items.map(i => {
                                  if (i.level === "label") {
                                    return (
                                      <li key={i.label} className="uk-nav-header">
                                        {i.label}
                                      </li>
                                    )
                                  }
                                  return (
                                    <li
                                      key={i.link}
                                      className={i.level === "1" ? "uk-margin-left" : undefined}
                                    >
                                      <PrismicLink to={i.link}>{i.label}</PrismicLink>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </li>
                        )
                      })}
                      <li>
                        <div id="customer-portal-link" className="uk-navbar-item uk-margin-remove">
                          <a
                            className="uk-button uk-border-pill uk-button-primary uk-margin-remove"
                            href="https://portal.vindicia.com/portal/login"
                          >
                            Merchant Portal
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <a
                  style={{ minHeight: "20px", padding: "4px", color: "#000" }}
                  className="uk-navbar-toggle uk-hidden@m uk-background-default uk-padding-small uk-border-rounded"
                  href="#offcanvas-nav"
                  data-uk-toggle="target: #offcanvas-nav"
                >
                  <span className="uk-float-right" data-uk-icon="icon: menu; ratio: 1.2" />
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

Navigation.propTypes = {
  data: PropTypes.array.isRequired,
}

export default Navigation
